<div class="overlay-page-container">
  <div class="popup-container">
    <div class="popup-box">
      <div class="close-modal" (click)="close()">
        <div class="ferrari-close"></div>
      </div>
      <div class="popup-title">
        <span>{{ 'RT.POPUP_ERROR.ERROR' | translate }}</span>
        <!-- <span>{{ 'RT.POPUP_CONTINUE_CONFIG.TITLE' | translate }}</span> -->
      </div>
      <div class="popup-description">
        <!-- <span>{{ 'The configuration will reset in' }}</span> -->
        <!-- <span>{{ 'RT.POPUP_CONTINUE_CONFIG.DESCRIPTION' | translate }}</span> -->
      </div>
      <!-- <div class="popup-timer">
        <span>{{ countdown$ | async }}</span>
      </div> -->
      <div class="popup-button" (click)="restart()">
        <!-- <span>{{ 'Continue configuration' }}</span> -->
        <span>{{ 'RT.POPUP_CONTINUE_CONFIG.RESTART' | translate }}</span>
      </div>
    </div>
  </div>
</div>
