import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { environment } from "src/environments/environment";
import { UtilityService } from "./utility.service";

export enum LogColors {
  Black = "color:black;",
  Orange = "color:darkOrange;",
  Blue = "color:dodgerBlue;",
  Green = "color:limeGreen;",
  Red = "color:red;",
  Purple = "color:darkViolet;",
  Pink = "color:hotPink;",
}

export enum LogTypes {
  Info,
  Warn,
  Error,
}

interface LogData {
  type: LogTypes;
  source: string;
  message: string;
  params?: any;
  color?: LogColors;
}

export enum LogLevel {
  NONE,
  ERROR,
  WARN,
  INFO,
  DEBUG
}
export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  static LogColors = LogColors;
  static LogTypes = LogTypes;

  static outputs: LogOutput[] = [];
  static level = LogLevel.DEBUG;

  static {
    LoggingService.level = environment.production ? LogLevel.WARN : LogLevel.DEBUG;
    const logLevelParam: string | null = UtilityService.getQueryParam("logLevel");
    if (!!logLevelParam && logLevelParam.toUpperCase() in LogLevel) {
      console.log("[RT] [LOGGER START] Setting up Log Level - parameter found and valid");
      LoggingService.level = LogLevel[logLevelParam.toUpperCase() as keyof typeof LogLevel];
    } else {
      console.log("[RT] [LOGGER START] Setting up Log Level - parameter NOT found or invalid");
    }
    console.log("[RT] [LOGGER START] Log Level set to: " + LoggingService.level + " (" + LogLevel[LoggingService.level] + ")");
  }

  constructor(
    private localStorage: LocalStorageService
  ) {}

  logInfo(source: string, color: LogColors = LogColors.Black, message: string, param?: any) {
    if (LoggingService.level >= LogLevel.INFO) {
      this.log({ type: LogTypes.Info, source, message, params: param, color});
      if (LoggingService.level >= LogLevel.DEBUG) {
        this.localStorage.addSessionLog({ message: message, invoker: source, severity: "info" });
        this.logEnrichedTrace(LogTypes.Info, source, message, param);
      }
    }
  }

  logInfoWithSessionStorage(source: string, color: LogColors = LogColors.Black, message: string, param?: any) {
    if (LoggingService.level >= LogLevel.INFO) {
      this.log({ type: LogTypes.Info, source, message, params: param, color});
      this.localStorage.addSessionLog({ message: message, invoker: source, severity: "info" });
      if (LoggingService.level >= LogLevel.DEBUG) {
        this.logEnrichedTrace(LogTypes.Info, source, message, param);
      }
    }
  }

  logWarn(source: string, message: string, param?: any) {
    if (LoggingService.level >= LogLevel.WARN) {
      this.log({ type: LogTypes.Warn, source, message, params: param, color: LogColors.Black });
      this.localStorage.addSessionLog({ message: message, invoker: source, severity: "warn" });
      if (LoggingService.level >= LogLevel.DEBUG) {
        this.logEnrichedTrace(LogTypes.Warn, source, message, param);
      }
    }
  }

  logError(source: string, message: string, error?: any) {
    if (LoggingService.level >= LogLevel.ERROR) {
      this.log({ type: LogTypes.Error, source, message, params: error, color: LogColors.Red });
      this.localStorage.addSessionLog({ message: message, invoker: source, severity: "error" });
      if (LoggingService.level >= LogLevel.DEBUG) {
        this.logEnrichedTrace(LogTypes.Error, source, message, error);
      }
    }
  }

  logAnalytics(source: string, color: LogColors = LogColors.Black, message: string, param?: any) {
    if (LoggingService.level >= LogLevel.INFO) {
      this.log({ type: LogTypes.Info, source, message, params: param, color});
    }
  }

  logGroup(groupName: string, logsData: LogData[]) {
    console.group(groupName);
    logsData.forEach((logData) => {
      this.log(logData);
    });
    console.groupEnd();
  }

  log(logData: LogData) {
    if (logData.type === LogTypes.Info) {
      //example message: '[INFO] [MonkeyWayService] Start initialization'
      const logMessage = `[RT] [INFO] %c[${logData.source}] ${logData.message}`;
      if (logData.params) {
        console.info(logMessage, logData.color, logData.params);
      } else {
        console.info(logMessage, logData.color);
      }
    } else if (logData.type === LogTypes.Warn) {
      //example message: '[WARN] [MonkeyWayService] Start initialization'
      const logMessage = `[RT] [WARN] %c[${logData.source}] ${logData.message}`;
      if (logData.params) {
        console.warn(logMessage, logData.color, logData.params);
      } else {
        console.warn(logMessage, logData.color);
      }
    } else if (logData.type === LogTypes.Error) {
      //example message: '[ERR] [MonkeyWayService] Start initialization'
      const logMessage = `[RT] [ERR] [${logData.source}] ${logData.message}`;
      if (logData.params) {
        console.error(logMessage, logData.params);
      } else {
        console.error(logMessage);
      }
    }
  }

  private getStackTrace(): any {
    const stack = new Error().stack;
    if (stack) {
      const stackArray = stack.split('\n');
      // Filtra la prima parte dello stack per ottenere la chiamata originale
      const callerStack = stackArray[4].trim(); // Salta la prima riga e prendi la seconda (dove è avvenuta la chiamata)
      return `\nCaller: ${callerStack}`;
    } else {
      return '';
    }
  }

  private logEnrichedTrace(type: LogTypes, source: string, message: string, param?: any) {
    const logMessage = `${LogTypes[type]} [${source}] ${message}`;
    const logData = { type, source, message, params: param, color: LogColors.Black };

    console.groupCollapsed(`Trace for ${logMessage}`);
    // Aggiunge parametri al log se ci sono
    !!logData.params ? console.log(logData.params) : null;
    // Stampa lo stack solo se l'utente lo espande
    console.trace();
    console.groupEnd();
  }

}
