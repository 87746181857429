import { isPlatformServer } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationProvider } from './configuration.provider';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private front_url = 'https://carconfigurator.ferrari.com';
  private defaultImage = `${this.front_url}/assets/cars/portofinom/packages/default/car-ferrari-portofino-m_splash.jpg`;

  constructor(
    private translate: TranslateService,
    private m: Meta,
    private t: Title,
  ) {
    // Set default meta tags
    this.t.setTitle("Ferrari Official Car Configurator")
    this.m.addTag({ name: 'description', content: "The Ferrari Configurator lets you build your own Ferrari down to the last detail" })
    this.m.addTag({ name: 'keywords', content: "Ferrari, F12berlinetta, F12 berlinetta, FF, GTC4Lusso, 488, 488 GTB, 488 Spider, 488 Pista, California T, configurator, configurate, build, personalize, personalized, individual, ixterieur, interieur, paintwork, rims, calipers, body parts, colour, material, seats, cockpit, steering wheel, roof, accesoiries, livery, steering wheel, wheel, stitching, infotainment" })
    this.m.addTag({ name: 'image', content: "" })
    this.m.addTag({ name: 'url', content: window.location.href })

    m.addTag({ property: 'og:image', content: '' })
    m.addTag({ property: 'og:title', content: '' })
    m.addTag({ property: 'og:description', content: '' })
    m.addTag({ property: 'og:url', content: '' })
    m.addTag({ property: 'og:site_name', content: '' })
    m.addTag({ property: 'og:type', content: '' })
    m.addTag({ name: 'twitter:card', content: '' })
    m.addTag({ name: 'twitter:site', content: '' })
    m.addTag({ name: 'twitter:title', content: '' })
    m.addTag({ name: 'twitter:description', content: '' })
    m.addTag({ name: 'twitter:creator', content: '' })
  }

  refresh(carName?: string, urlId?: string, image?: string, url?: string) {
    this.meta(carName)
    this.facebook(carName, urlId, image, url)
    this.twitter(carName, urlId)
  }

  refreshMuseum(location: string, image: string) {
    this.metaMuseum(location, image);
  }

  private meta(carName?: string) {
    if (carName) {
      this.translate.get('RT.META.TITLE.STEP.DEFAULT', { value: carName }).subscribe(p => this.t.setTitle(p));
      this.translate.get('RT.META.DESCRIPTION.STEP.DEFAULT', { value: carName }).subscribe(p => this.m.updateTag({ name: 'description', content: p }, 'name="description"'));
    } else {
      this.translate.get('RT.META.TITLE.DEFAULT').subscribe(p => this.t.setTitle(p));
      this.translate.get('RT.META.DESCRIPTION.DEFAULT').subscribe(p => this.m.updateTag({ name: 'description', content: p }, 'name="description"'))
    }
    this.translate.get('RT.META.KEYWORDS').subscribe(p => { this.m.updateTag({ name: 'keywords', content: p }, 'name="keywords"') })
  }

  private metaMuseum(location: string, image: string) {
    let museumTitleKey = 'RT.MUSEUM.' + location.toUpperCase() + '.TITLE';
    let basicTitle = 'Ferrari Museum Experience';
    this.translate.get(museumTitleKey).subscribe(p => {
      if (p !== '' && p !== museumTitleKey) {
        this.t.setTitle(p);
      } else {
        this.t.setTitle(basicTitle);
      }
    });
    let museumDescriptionKey = 'RT.MUSEUM.' + location.toUpperCase() + '.IMAGE.DESCRIPTION';
    let basicDescription = 'This is my Ferrari made during Ferrari Museum experience';
    this.translate.get(museumDescriptionKey).subscribe(p => {
      if (p !== '' && p !== museumDescriptionKey) {
        this.m.updateTag({ name: 'description', content: p }, 'name="description"');
      } else {
        this.m.updateTag({ name: 'description', content: basicDescription }, 'name="description"');
      }
    });
    this.m.updateTag({ name: 'image', content: image }, 'name="image"');
    this.m.updateTag({ property: 'og:image', content: image }, 'property="og:image"');
  }

  private facebook(carName?: string, urlId?: string, image?: string, url?: string) {
    // og:type - The type of your object, e.g., "video.movie". Depending on the type you specify, other properties may also be required.
    // <meta property="og:type" content="website" />
    this.m.updateTag({ property: 'og:type', content: "website" }, 'property="og:type"'); //"product"?
    // og:site_name - If your object is part of a larger web site, the name which should be displayed for the overall site. e.g., "IMDb".
    // <meta property="og:site_name" content="IMDb" />
    this.translate.get("RT.META.FACEBOOK.SITENAME").subscribe(p => this.m.updateTag({ property: 'og:site_name', content: p }, 'property="og:site_name"'))

    // og:title - The title of your object as it should appear within the graph, e.g., "The Rock".
    // <meta property="og:title" content="The Rock" />
    if (carName) {
      this.translate.get("RT.META.FACEBOOK.TITLE", { value: carName }).subscribe(p => this.m.updateTag({ property: 'og:title', content: p }, 'property="og:title"'))
    } else {
      this.translate.get("RT.META.FACEBOOK.TITLE.DEFAULT").subscribe(p => this.m.updateTag({ property: 'og:title', content: p }, 'property="og:title"'))
    }
    // og:image - An image URL which should represent your object within the graph.
    // <meta property="og:image" content="http://ia.media-imdb.com/images/rock.jpg" />
    if (image) {
      this.m.updateTag({ property: 'og:image', content: image }, 'property="og:image"')
    } else {
      this.m.updateTag({ property: 'og:image', content: this.defaultImage }, 'property="og:image"')
    }
    // og:url - The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "http://www.imdb.com/title/tt0117500/".
    // <meta property="og:url" content="http://www.imdb.com/title/tt0117500/" />
    if (url) {
      this.m.updateTag({ property: 'og:url', content: url }, 'property="og:url"')
    } else {
      this.m.updateTag({ property: 'og:url', content: this.front_url }, 'property="og:url"')
    }
    // og:description - A one to two sentence description of your object.
    // <meta property="og:description" content="Sean Connery found fame and fortune as the suave, sophisticated British agent, James Bond." />
    if (carName && urlId) {
      this.translate.get("RT.META.FACEBOOK.DESCRIPTION", { carName: carName, urlId: urlId }).subscribe(p => this.m.updateTag({ property: 'og:description', content: p }, 'property="og:description"'))
    } else {
      this.translate.get("RT.META.FACEBOOK.DESCRIPTION.DEFAULT").subscribe(p => this.m.updateTag({ property: 'og:description', content: p }, 'property="og:description"'))
    }
  }

  private twitter(carName?: string, urlId?: string) {
    // twitter:card The card type
    this.m.updateTag({ name: 'twitter:card', content: "summary_large_image" }, 'name="twitter:card"')
    // twitter:site @username of website. Either twitter:site or twitter:site:id is required.
    this.m.updateTag({ name: 'twitter:site', content: "@ferrari" }, 'name="twitter:site"')
    // twitter:creator @username of content creator
    this.m.updateTag({ name: 'twitter:creator', content: "@ferrari" }, 'name="twitter:creator"')

    // twitter:title Title of content (max 70 characters)
    if (carName) {
      this.translate.get("RT.META.TWITTER.TITLE", { value: carName }).subscribe(p => this.m.updateTag({ name: 'twitter:title', content: p }, 'name="twitter:title"'))
    } else {
      this.translate.get("RT.META.TWITTER.TITLE.DEFAULT").subscribe(p => this.m.updateTag({ name: 'twitter:title', content: p }, 'name="twitter:title"'))
    }
    // twitter:description Description of content (maximum 200 characters)
    if (carName && urlId) {
      this.translate.get("RT.META.TWITTER.DESCRIPTION", { carName: carName, urlId: urlId }).subscribe(p => this.m.updateTag({ name: 'twitter:description', content: p }, 'name="twitter:description"'))
    } else {
      this.translate.get("RT.META.TWITTER.DESCRIPTION.DEFAULT").subscribe(p => this.m.updateTag({ name: 'twitter:description', content: p }, 'name="twitter:description"'))
    }
  }


}

