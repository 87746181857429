import { Injectable } from '@angular/core';
import {
  combineLatest,
  ConnectableObservable,
  merge as mergeStatic,
  Observable,
  of,
  ReplaySubject,
  Subscriber,
  throwError as observableThrowError,
  timer,
  BehaviorSubject
} from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter, finalize, first,
  map,
  mergeMap,
  publishReplay,
  refCount,
  retryWhen,
  shareReplay, startWith,
  switchMap,
  take,
  tap,
  debounceTime
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApplicationCommand } from './application-interfaces';
import { IntentInterceptor, createVetoableObservable } from './intent-interceptor';
import { ConnectionState, StateLineClientService } from './state-line-client.service';
import { includes, isEqual, cloneDeep } from 'lodash';
import { ENDPOINT_IS_READY, ENDPOINT_GET_APPLICATION_STATE } from './application-endpoints';
import { COMMAND_CURRENT_STATE_MACHINE_STATE, COMMAND_REQUEST_STATE } from './application-commands';
import { STATE_APPLICATION_START } from './state-machine-states';
import { TOPIC_ENGINE_COMMANDS } from './application-topics';
import { MonkeyWayService } from '../services/monkey-way.service';
import { LoggingService } from '../services/logging.service';


export interface ApplicationState {
  states: ApplicationCommand[];
}

interface IsReadyPayload {
  success: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ApplicationStateService {

  readonly currentStateMachineState$: Observable<string>;

  private readonly currentStateMachineStateSubject = new ReplaySubject<string>(1);
  private readonly currentApplicationStateSubject = new ReplaySubject<ApplicationState>(1);
  private readonly currentApplicationState$: Observable<ApplicationState>;
  private readonly applicationAvailabilityState$: Observable<boolean>;
  private readonly applicationAuthenticatedState$: Observable<boolean>;

  private stateChangeInterceptors: IntentInterceptor<string>[] = [];
  private commandInterceptors: IntentInterceptor<ApplicationCommand>[] = [];

  private _serverCallInProgressSubject: BehaviorSubject<boolean> | undefined;
  private get serverCallInProgressSubject () {
    if (!this._serverCallInProgressSubject) {
      this._serverCallInProgressSubject = new BehaviorSubject<boolean>(false);
    }
    return this._serverCallInProgressSubject;
  }

  private serverCallActiveCount = 0;

  constructor (
    private stateLineClientService: StateLineClientService,
    private monkeyWayService: MonkeyWayService,
    private logger: LoggingService
  ) {
    this.currentStateMachineState$ = this.currentStateMachineStateSubject.asObservable();
    this.currentApplicationState$ = this.currentApplicationStateSubject.asObservable();

    this.applicationAvailabilityState$ = this.stateLineClientService.getConnectionState()
      .pipe(
        distinctUntilChanged((prevConnectionState, connectionState) => prevConnectionState === connectionState, (connectionState) => {
          return connectionState ? connectionState.state : null;
        }),
        switchMap((connectionState: ConnectionState) => {
          this.logger.logInfoWithSessionStorage('ApplicationStateService', LoggingService.LogColors.Green, 'Connection state changed to:', connectionState.state);
          if (connectionState.state === 'CONNECTED') {
            const endpointsAvailable$: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

              this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, 'Calling this endpoint to check wheter application is ready:', ENDPOINT_IS_READY);

              const callEndpointSubscription = this.stateLineClientService.callJson<IsReadyPayload>(ENDPOINT_IS_READY, undefined, true)
                .pipe(
                  map((payload) => {
                    if (payload.success) {
                      return true;
                    }
                    throw new Error('Application not ready yet');
                  }),
                  catchError((error) => {
                    subscriber.next(false);
                    return observableThrowError(error);
                  })
                )
                .subscribe(subscriber);

              return () => {
                callEndpointSubscription.unsubscribe();
              };
            });
            return endpointsAvailable$
              .pipe(
                tap((isReady) => {
                  if (isReady) {
                    this.logger.logInfoWithSessionStorage('ApplicationStateService', LoggingService.LogColors.Green, 'Application IS ready!');
                  } else {
                    this.logger.logInfoWithSessionStorage('ApplicationStateService', LoggingService.LogColors.Green, 'Application NOT ready yet.');
                  }
                }),
                retryWhen((errors) => {
                  return errors
                    .pipe(
                      mergeMap(() => timer(1000)) // retry after 1 sec
                    );
                })
              );
          }
          else {
            this.logger.logInfoWithSessionStorage('ApplicationStateService', LoggingService.LogColors.Green, 'Connection state DISCONNECTED, streaming not available');
            this.monkeyWayService.streamingAvailable$.next(false);
          }
          return of(false);
        }),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.applicationAuthenticatedState$ = combineLatest(
      this.applicationAvailabilityState$,
      this.currentStateMachineState$
    ).pipe(
        map((data: [boolean, string]) => {
          const applicationAvailable = data[0];
          const stateMachineState = data[1];
          return applicationAvailable &&
            !includes([STATE_APPLICATION_START], stateMachineState);
        }),
        startWith(false),
        distinctUntilChanged(),
        shareReplay(1)
      );

    this.applicationAvailabilityState$
      .pipe(
        filter(available => available),
        switchMap(() => {
          return this.stateLineClientService.callJson<ApplicationCommand>(ENDPOINT_GET_APPLICATION_STATE, undefined, true)
            .pipe(
              tap((applicationState) => this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, 'Got new application state:', applicationState.stringValue)),
              retryWhen(errors => errors.pipe(delay(1000), take(10))),
              map((applicationState: ApplicationCommand): ApplicationState | undefined => {
                try {
                  this.updateApplicationState(applicationState.stringValue);
                  return applicationState.stringValue;
                } catch (error: any) {
                  if (error.name === 'SyntaxError') {
                    // tslint:disable-next-line: max-line-length
                    this.logger.logError('ApplicationStateService', 'The reason for the error could be invalid translation-strings. Be sure to format strings according to https://github.com/lephyrus/ngx-translate-messageformat-compiler#usage');
                  }
                  throw error;
                }
              })
            );
        })
      )
      .subscribe(
        (applicationState) => this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, 'Updated application state:', applicationState),
        (reason) => this.logger.logError('ApplicationStateService', 'Error while updating application state', reason)
      );
  }

  subscribeToCommandsInit(){
    this.subscribeToApplicationCommand(COMMAND_CURRENT_STATE_MACHINE_STATE)
    .pipe(debounceTime(100),map((command: ApplicationCommand) => {
      if (!!command){
        const message = `[${COMMAND_CURRENT_STATE_MACHINE_STATE}] with identifier: ${command.identifier} is:`;
        this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, message, command);
        if (!!command.identifier){
          this.updateStateMachineState(command.identifier);
        }
      }
    })
    )
    .subscribe({
      // next : (command: ApplicationCommand) => {
      //   if (command && command.identifier) {
      //     console.log(`[${COMMAND_CURRENT_STATE_MACHINE_STATE}] is : ${command} with identifier: ${command.identifier}`);
      //     this.updateStateMachineState(command.identifier);
      //   }
      // },
      error: (error) => this.logger.logError('ApplicationStateService', `Subscription to command [${COMMAND_CURRENT_STATE_MACHINE_STATE}] failed:`, error)
    });

  // subscribe to commands-topic to keep application-state updated
  this.stateLineClientService.subscribeJson<ApplicationCommand>(TOPIC_ENGINE_COMMANDS)
    .pipe(
      map((command: ApplicationCommand) => {
        if (!environment.production) {
          this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, `Incoming command [${command.type}]`, command);
        }
        this.currentApplicationState$
          .pipe(
            first()
          )
          .subscribe((applicationState) => {
            let states = applicationState.states.filter((state) => state.type !== command.type);

            states = [command, ...states];

            this.updateApplicationState({
              states
            });
          });
      })
    )
    .subscribe({
      next: (command) =>{
        this.logger.logInfo('ApplicationStateService', LoggingService.LogColors.Green, `Incoming command complete`);
      },
      error: (error) => this.logger.logError('ApplicationStateService', `Subscription to topic [${TOPIC_ENGINE_COMMANDS}] failed`, error)
    });
  }

  /**
   * Get an observable that emits either true or false indicating if the application is available or not.
   * @return {Observable<boolean>}
   */
  getApplicationAvailabilityState (): Observable<boolean> {
    return this.applicationAvailabilityState$;
  }

  /**
   * Get an observable that emits either true or false indicating if the user has authenticated himself and
   * the application is ready to answer calls to dealer-related information.
   * @return {Observable<boolean>}
   */
  getApplicationAuthenticationState (): Observable<boolean> {
    return this.applicationAuthenticatedState$;
  }

  subscribeToApplicationCommand (type: string, identifier?: string): Observable<ApplicationCommand | any> {
    return this.currentApplicationState$
      .pipe(
        map((applicationState: ApplicationState) => {
          return applicationState.states.find((stateCommand) => {
            let matches = stateCommand.type === type;
            if (matches && identifier) {
              matches = stateCommand.identifier === identifier;
            }
            return matches;
          });
        }),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        map(command => cloneDeep(command))
      );
  }

  /**
   * Register an IntentInterceptor that will be asked in case a state transition is requested and may decide
   * if the state transition may occur or not.
   * @param {IntentInterceptor} stateChangeInterceptor
   * @return {() => void}
   */
  registerStateChangeInterceptor (stateChangeInterceptor: IntentInterceptor<string>): () => void {
    this.stateChangeInterceptors = [...this.stateChangeInterceptors, stateChangeInterceptor];

    return () => {
      this.stateChangeInterceptors = this.stateChangeInterceptors.filter(interceptor => stateChangeInterceptor !== interceptor);
    };
  }

  /**
   * Request that the application transitions to a certain state.
   * This request is possibly intercepted by registered IntentInterceptors.
   *
   * @param {string} state
   * @return {Observable<void>}
   */
  requestStateTransition (state: string, allowVeto: boolean = true) {
    const observableFactory = () => {
      return this.publishCommand({
        type: COMMAND_REQUEST_STATE,
        identifier: state
      });
    };
    if (allowVeto) {
      return createVetoableObservable<void, string>(state, this.stateChangeInterceptors, observableFactory);
    }
    return observableFactory();
  }

  updateStateMachineState (state: string) {
    this.currentStateMachineStateSubject.next(state);
  }

  /**
   * Register an IntentInterceptor that will be asked in case a command should be published and may decide
   * if the publish should happen or not.
   * @param {IntentInterceptor} commandInterceptor
   * @return {() => void}
   */
  registerCommandInterceptor (commandInterceptor: IntentInterceptor<ApplicationCommand>): () => void {
    this.commandInterceptors = [...this.commandInterceptors, commandInterceptor];

    return () => {
      this.commandInterceptors = this.commandInterceptors.filter(interceptor => commandInterceptor !== interceptor);
    };
  }

  /**
   * Publish an ApplicationCommand to the app.
   * This request is possibly intercepted by registered IntentInterceptors.
   *
   * @param {ApplicationCommand} command
   * @return {ConnectableObservable<void>}
   */
  publishCommand (command: ApplicationCommand) {
    return createVetoableObservable<void, ApplicationCommand>(command, this.commandInterceptors, () => {
      return this.stateLineClientService.publish('com.mackevision.ui.commands', command);
    });
  }

  private updateApplicationState (state: ApplicationState) {
    this.currentApplicationStateSubject.next(state);
  }

  public handleServerCallInProgress<T>(sourceObservable$: Observable<T>) {
    const serverCallbackFinished = this.onServerCallStart();
    const sharedObservable$ = sourceObservable$.pipe(
      finalize(() => serverCallbackFinished()),
      publishReplay(1),
      refCount()
    );
    sharedObservable$.subscribe(
      () => void 0,
      () => void 0
    );
    return sharedObservable$;
  }

  private onServerCallStart () {
    this.serverCallActiveCount++;
    if (this.serverCallActiveCount === 1) {
      this.serverCallInProgressSubject.next(true);
    }
    let finished = false;
    return () => {
      if (finished) {
        this.logger.logWarn('ApplicationStateService', 'Server call already finished. No need to call multiple times.');
        return;
      }
      finished = true;
      this.serverCallActiveCount--;
      if (this.serverCallActiveCount === 0) {
        this.serverCallInProgressSubject.next(false);
      }
    };
  }

}
