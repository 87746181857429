import { Observable } from "rxjs";
import { Option, OptionWithVariant, VehicleConfigurationMeta } from "./configuration-meta";
import { filter } from 'rxjs/operators';
import { LoggingService } from "../services/logging.service";

// export enum conflictOrigin {
//   RESOLVE_ALTERNATIVE_FOR_MANDATORY = "RESOLVE_ALTERNATIVE_FOR_MANDATORY",
//   EXCLUDE_OPTIONS_BY_OPTION = "EXCLUDE_OPTIONS_BY_OPTION",
//   ONE_OPT_MANDATORY_IN_LIST = "ONE_OPT_MANDATORY_IN_LIST",
//   ENABLED_WHEN_OPTION_SELECTED = "ENABLED_WHEN_OPTION_SELECTED",
//   MANDATORY_WHEN = "MANDATORY_WHEN",
//   GROUP_COMPLEMENTARY = "GROUP_COMPLEMENTARY",
//   COLOR_PALETTE_SUBSTITUTION = "COLOR_PALETTE_SUBSTITUTION",
// }
export interface GeneralRuleEngineResponse {
  origins: string[];
  options?: Option[];
  trigger?: Option[];
  error?: any;
}
const conflictOriginMap: Map<string, string[]> = new Map([
  ["ForceSelection", ["EXCLUDE_OPTIONS_BY_OPTION", "MANDATORY_WHEN"]],
  [
    "AlternativeSelection",
    [
      "RESOLVE_ALTERNATIVE_FOR_MANDATORY",
      "ONE_OPT_MANDATORY_IN_LIST",
      "ENABLED_WHEN_OPTION_SELECTED",
      "GROUP_COMPLEMENTARY",
    ],
  ],
  ["ColorSubstitution", ["COLOR_PALETTE_SUBSTITUTION"]],
  ["Error", ["Error"]],
]);

export class RuleEngineResponse implements GeneralRuleEngineResponse {
  options?: Option[];
  origins: string[] = [];
  alternatives?: Option[];
  coupledAlternatives: any [][] = [];
  toSelect?: Option[];
  toDeSelect?: Option[];
  dependecyMap: Map<string, Option[]> = new Map<string, Option[]>();
  dependenciesColors?: Option[];

  trigger?: Option[];

  constructor(
    data: any,
    payloadOpt: Option | undefined,
    packageExclusionList: string[] | undefined,
    configMeta?: VehicleConfigurationMeta
  ) {
    if(!data.error && !!data.options && !!configMeta){
      this.options = data.options.map((m: any) =>
        configMeta.getOption(m.optionId, m.variantId)
      ).filter((f: any) => !!f);
    }
    if (
      !data.error &&
      !!configMeta &&
      !!data.conflict &&
      !!data.conflict.conflict_origin
    ) {
      this.origins = this.findConflictOrigin(data.conflict.conflict_origin);
      for (let origin of this.origins) {
        switch (origin) {
          case "ForceSelection":
            this.toDeSelect = data.conflict.to_deselect
            // .filter((f: any) => !!configMeta.getOption(f.optionId, f.variantId))
            .map((m: any) =>
              configMeta.getOption(m.optionId, m.variantId)
            );
            this.toSelect = data.conflict.to_select
            .filter((f: any) => !!configMeta.getOption(f.optionId, f.variantId))
            .map((m: any) =>
              configMeta.getOption(m.optionId, m.variantId)
            );
            if (!!data.conflict.trigger) {
              this.trigger = data.conflict.trigger
              .filter((f: any) => !!configMeta.getOption(f.optionId, f.variantId))
              .map((m: any) =>
                configMeta.getOption(m.optionId, m.variantId)
              );
            } else {
              // it should always come here till BE upgrade
              if(!!payloadOpt){
                this.trigger = [payloadOpt];
              }
            }
            // we need to make sure toDeselect & toSelect presence in options
            if (!!this.toSelect && this.toSelect.length > 0 && !!payloadOpt){
              this.toSelect = this.toSelect.filter(f => f.id !== payloadOpt.id);
              // check if option included in options if not add it
              // this.modifyOptions(this.toSelect, true);
            }
            if (!!this.toDeSelect && this.toDeSelect.length > 0 && !!payloadOpt){
              this.toDeSelect = this.toDeSelect.filter(f => f.id !== payloadOpt.id);
              // this.modifyOptions(this.toDeSelect, false);
            }


            break;
          case "AlternativeSelection":
              this.alternatives = data.conflict.alternatives? data.conflict.alternatives
              .filter((f: any) => !!configMeta.getOption(f.optionId, f.variantId))
              .map((m: any) =>
                configMeta.getOption(m.optionId, m.variantId)
              ) : null;
              if (!!this.alternatives) {
                this.alternatives = this.alternatives.filter(f => !!f)
                .filter(suggestion => {
                  // check exclusion list for current package
                    return !!packageExclusionList ? !packageExclusionList.includes(suggestion.id) : true;
                });
              }
              if (!!data.conflict.coupled_alternatives && data.conflict.coupled_alternatives.length > 0){
                this.coupledAlternatives = [];
                data.conflict.coupled_alternatives.forEach((alternativeList: any[]) => {
                  const list = alternativeList.filter((f: any) => !!configMeta.getOption(f.optionId, f.variantId))
                  .map((m: any) =>
                    configMeta.getOption(m.optionId, m.variantId)
                  );
                  this.coupledAlternatives.push(list);
                });
                console.log('Rule engine conflict, this is the alternativeList:', this.coupledAlternatives);
              }
              if (!!data.conflict.trigger) {
                this.trigger = data.conflict.trigger.map((m: any) =>
                  configMeta.getOption(m.optionId, m.variantId)
                );
              } else {
                // just in case of undefined
                if(!!payloadOpt){
                  this.trigger = [payloadOpt];
                }
              }

            break;
          case "ColorSubstitution":
            data.conflict.alternative_dependencies_colors.forEach(
              (alternative_dependencies_colors: any, i: number) => {
                this.dependecyMap.set(
                  i.toString(),
                  alternative_dependencies_colors.map((m: any) =>
                    configMeta.getOption(m.optionId, m.variantId)
                  )
                );
              }
            );
            if (!!data.conflict.dependencies_colors) {
              this.dependenciesColors = data.conflict.dependencies_colors.map(
                (m: any) => configMeta.getOption(m.optionId, m.variantId)
              );
            }
            if (!!data.conflict.trigger) {
              this.trigger = data.conflict.trigger.map((m: any) =>
                configMeta.getOption(m.optionId, m.variantId)
              );
            } else {
              // just in case of undefined
              if(!!payloadOpt){
                this.trigger = [payloadOpt];
              }
            }
            break;
        }
      }

      // switch (this.origins) {
      //   case conflictOrigin.RESOLVE_ALTERNATIVE_FOR_MANDATORY:
      //     this.alternatives = data.conflict.alternatives.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     if (!!data.conflict.trigger) {
      //       this.trigger = data.conflict.trigger.map((m: any) =>
      //         configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     break;
      //   case conflictOrigin.EXCLUDE_OPTIONS_BY_OPTION:
      //     this.toDeSelect = data.conflict.to_deselect.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     this.toSelect = data.conflict.to_select.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     break;
      //   case conflictOrigin.ONE_OPT_MANDATORY_IN_LIST:
      //     if (!!data.conflict.trigger) {
      //       this.trigger = data.conflict.trigger.map((m: any) =>
      //         configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     this.alternatives = data.conflict.alternatives.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     break;
      //   case conflictOrigin.ENABLED_WHEN_OPTION_SELECTED:
      //     if (!!data.conflict.trigger) {
      //       this.trigger = data.conflict.trigger.map((m: any) =>
      //         configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     this.alternatives = data.conflict.alternatives.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     break;
      //   case conflictOrigin.MANDATORY_WHEN:
      //     this.toDeSelect = data.conflict.to_deselect.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     this.toSelect = data.conflict.to_select.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     break;
      //   case conflictOrigin.GROUP_COMPLEMENTARY:
      //     if (!!data.conflict.trigger) {
      //       this.trigger = data.conflict.trigger.map((m: any) =>
      //         configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     this.alternatives = data.conflict.alternatives.map((m: any) =>
      //       configMeta.getOption(m.optionId, m.variantId)
      //     );
      //     break;
      //   case conflictOrigin.COLOR_PALETTE_SUBSTITUTION:
      //     if (!!data.conflict.trigger) {
      //       this.trigger = data.conflict.trigger.map((m: any) =>
      //         configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     data.conflict.alternative_dependencies_colors.forEach(
      //       (alternative_dependencies_colors: any, i: number) => {
      //         this.dependecyMap.set(
      //           i.toString(),
      //           alternative_dependencies_colors.map((m: any) =>
      //             configMeta.getOption(m.optionId, m.variantId)
      //           )
      //         );
      //       }
      //     );
      //     if (!!data.conflict.dependencies_colors) {
      //       this.dependenciesColors = data.conflict.dependencies_colors.map(
      //         (m: any) => configMeta.getOption(m.optionId, m.variantId)
      //       );
      //     }
      //     break;
      // }
    }
  }

  findConflictOrigin(list: string[]): string[] {
    let bestMatches: string[] = [];
    let maxMatches = 0;

    conflictOriginMap.forEach((originList: string[], key) => {
      const matchCount = list.filter((item) => originList.includes(item)).length;
      if (matchCount > maxMatches) {
        bestMatches = [key];
        maxMatches = matchCount;
      } else if (matchCount === maxMatches) {
        bestMatches.push(key);
      }
    });

    return bestMatches;
  }

  private async fetchGlobalRules(list: Observable<any[]>){
    return await list.toPromise();
  }

  private modifyOptions(opts: Option[], isSelected: boolean){
    for(let opt of opts){
      if (opt.values){
        const value = opt.values[0];
        if (value){
          if (this.options){
            const optIndex = this.options.findIndex(opt => opt.values && opt.values.some(val => value.optionId === val.optionId && value.variantId === val.optionId));
            if ((optIndex === -1) && isSelected){
              // we should add
              this.options.push(opt);
            } else if((optIndex !== -1) && !isSelected) {
              // we should remove
              this.options.slice(optIndex, 1);
            }
          }
        }
      }
    }
  }
}
