import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { Observable, of, combineLatest, debounceTime, take } from 'rxjs';
import { UtilityService } from "src/app/core/services/utility.service";
import { FerrariState } from "../../shared/application-interfaces";
import { setCarPackage, setSelectedCar, updateConfigLevel } from "../../state/actions";
import { LoggingService } from "src/app/core/services/logging.service";

@Injectable({
  providedIn: "root",
})
export class PackageSelectionResolver implements Resolve<any> {
  constructor(
    private util: UtilityService,
    private route: ActivatedRoute,
    private store: Store<FerrariState>,
    private logger: LoggingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.logger.logInfo('PackageSelectionResolver', LoggingService.LogColors.Blue, 'RESOLVER - Retrieve info about selected car package');
    const carModelId = route.paramMap.get("carModelId") || null;
    const packageId = route.paramMap.get("packageId") || undefined;
    const car$ = this.util.getCarModel(carModelId);

    if (!!car$) {
      car$.subscribe((car) => {
        if (!!car && !!car.id && !!packageId && car.packages.includes(packageId)) {
          this.logger.logInfo('PackageSelectionResolver', LoggingService.LogColors.Blue, 'Car package selected: ', packageId);
          this.store.dispatch(setCarPackage({ packageId: packageId }));
        }
      });
    }
    return of("");
  }
}
