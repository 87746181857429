import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { ElementRef, Injectable } from "@angular/core";
import { StreamingService } from "@monkeyway/streaming-lib/dist/streaming/streaming";
import { ServiceOptions } from "@monkeyway/streaming-lib/dist/types/service-options";
import {
  ClientStreamInfo,
  StreamInfo,
} from "@monkeyway/streaming-lib/dist/types/stream-info";
import { StreamingControlService } from "@monkeyway/streaming-control-lib/dist/control";
import {
  Subject,
  Subscription,
} from "@monkeyway/streaming-lib/node_modules/rxjs";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setSessionIdStreaming, streamingModeOff } from "src/app/state/actions";
import { AnalyticsService } from "./analytics.service";
import { ConfigurationProvider } from "./configuration.provider";
import { MatDialog } from "@angular/material/dialog";
import { MuseumPopupErrorComponent } from "src/app/features/configuration-area/museum-popup-error/museum-popup-error.component";
import { Api } from "./_api";
import { CarConfig } from "src/environments/environment-interface";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root",
})
export class MonkeyWayService {
  private streamingService: StreamingService | undefined;

  private streamInfo$: Observable<ClientStreamInfo> | undefined;
  private _streamInfo: ClientStreamInfo | null = null;
  private streamingControlService: StreamingControlService =
    new StreamingControlService();
  private _streamSubscription: Subscription | null = null;

  private _streamElement: ElementRef<HTMLVideoElement> | null | undefined;
  streamControlSubs$: Subject<boolean> = new Subject();
  streamingAvailable$: Subject<boolean> = new Subject();
  machineSerialNumberMuseum: any;

  constructor(
    private http: HttpClient,
    private store: Store<FerrariState>,
    private analytics: AnalyticsService,
    private cfg: ConfigurationProvider,
    private dialog: MatDialog,
    private api: Api,
    private logger: LoggingService
  ) {

  }

  get srcObject(): any {
    return this._streamInfo && this._streamInfo.stream;
  }

  setStreamElement(element: ElementRef<HTMLVideoElement>): void {
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Set StreamElement:', element);
    this._streamElement = element;

    if (this._streamSubscription) {
      this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Restarting subscription');
      this.streamControlSubs$.next(true);
    }

    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Starting subscription');
  }

  swipeCar(x: number, y: number) {
    this.streamingControlService.move(x, y, true);
  }

  destroyStreamElement(): void {
    this.streamingControlService.close();
    this._streamElement = null;
  }

  startSession(modelId: string): void {
    this.logger.logInfoWithSessionStorage('MWService', LoggingService.LogColors.Purple, 'Starting process for starting session for model:', modelId);
    const monkeyWayConfig = this.cfg.carConfigList.find(
      (x: any) => x.modelId === modelId
    );
    if (!!monkeyWayConfig) {
      if (this.cfg.isMuseum) {
        this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Starting process for starting museum session', monkeyWayConfig);
        this.startMuseumStreamingSession(monkeyWayConfig);
      } else {
        this.start3DStreamingSession(monkeyWayConfig, null);
      }
    }
  }

  startMuseumStreamingSession(monkeyWayConfig: CarConfig) {
    // retrieve the machine serial number for museum
    this.api.getMachineSerialNumberMuseum()
    .pipe(
      catchError((error) => {
        console.error(`Error while retrieving museum serial number: ${error}`);
        this.analytics.error(`Error while retrieving museum serial number: ${error}`);
        return of(null);
      })
    ).subscribe((machineSerialNumberMuseum) => {
      // even if the serial number is null, start streaming service anyway
      this.start3DStreamingSession(monkeyWayConfig, machineSerialNumberMuseum);
    })
  }

  start3DStreamingSession(monkeyWayConfig: CarConfig, machineSerialNumberMuseum: any = null) {
    // if the machine serial number is available, start streaming service with it,
    // otherwise, start streaming service without it
    const streamingOptions: ServiceOptions = {
      baseUrl: monkeyWayConfig.baseUrl,
      appEnvId: monkeyWayConfig.appEnvId,
      apiKey: monkeyWayConfig.apiKey,
    };
    this.streamingService = new StreamingService(streamingOptions);
    let streamInfo$;
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Starting streaming session on StreamingService');
    if (!!machineSerialNumberMuseum) {
      // start streaming service with machine serial number if available
      this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Serial number for museum:', machineSerialNumberMuseum);
      streamInfo$ = this.streamingService.start(
        {
          immediate: false,
          waitForProvisioning: () => {
            this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Waiting for provisioning renderer');
          },
          requiredSpecs: machineSerialNumberMuseum
        },
        { useAudio: true }
      );
    } else {
      // start streaming service without machine serial number
      streamInfo$ = this.streamingService.start(
        {
          immediate: false,
          waitForProvisioning: () => {
            this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Waiting for provisioning renderer');
          },
        },
        { useAudio: true }
      );
    }
    this.subscribeToStreamInfo(streamInfo$);
  }

  subscribeToStreamInfo(streamInfo$: any): void {
    this._streamSubscription = streamInfo$.subscribe({
      next: (info: any) => {
        this._streamInfo = info;
        this.logger.logInfoWithSessionStorage('MWService', LoggingService.LogColors.Purple, 'Streaming information received:', info);
        if (this.cfg.isMuseum) {
          this._streamInfo = info;
          this.onConnected();
        } else {
          this.subscribeToMwMovement(info);
        }
        this.store.dispatch(
          setSessionIdStreaming({ sessionIdStreaming: info.session.id })
        );
      },
      error: (error: any) => {
        const descriptionError = `Error on stream subscription: ${error} - activating fallback on 2D configurator`;
        this.logger.logWarn('MWService', descriptionError, error);
        this.analytics.error(descriptionError);
        this.streamingAvailable$.next(false);
        this.store.dispatch(streamingModeOff());
        this.store.dispatch(
          setSessionIdStreaming({ sessionIdStreaming: undefined })
        );
      }
    });
  }

  subscribeToMwMovement(info: StreamInfo) {
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Waiting to start subscription on streaming movements');
    setTimeout(() => {
      this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Starting subscription on streaming movements');
      if (!!info && !!this._streamElement) {
        const options = this.streamingControlService.createOptions(info);
        this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Asking StreamingControlService to connect to streaming');
        this.streamingControlService
          .connect(this._streamElement.nativeElement, options)
          .subscribe(
            async (connected) => {
              if (connected) {
                this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'StreamingControlService connected to streaming');
                this._streamInfo = info;
                this.onConnected();
              } else {
                // close streaming connection if mouse/keyboard connection gets lost
                this.logger.logWarn('MWService', 'StreamingControlService lost connection to streaming');
                this.analytics.error(`Error in subscribeToMwMovement: connection lost`);
                this.streamingAvailable$.next(false);
                this.onDisconnected();
                await this.stopSession();
              }
              //   this.onConnected();
            },
            async (err) => {
              this.logger.logError('MWService', 'StreamingControlService error in connection to streaming', err);
              this.analytics.error(`error connecting to renderer: ${err}`);
              this._streamInfo = info; // TODO
              setTimeout(() => {
                this.streamingAvailable$.next(false);
              }, 2000);
              this.onDisconnected();
              await this.stopSession();
            }
          );
      }
    }, 1000);
  }

  async stopSession() {
    if (!this._streamSubscription) {
      return;
    }
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Starting request to stop streaming session');
    this.onDisconnected();
    this._streamSubscription.unsubscribe();
    this._streamSubscription = null;
    this.store.dispatch(
      setSessionIdStreaming({ sessionIdStreaming: undefined })
    );
  }

  stopMonkeyWaySession() {
    if (!this._streamSubscription) {
      return;
    }
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'Asking StreamingService to stop streaming session');
    this.streamingService?.stop();
    this._streamSubscription.unsubscribe();
    this._streamSubscription = null;
    this.destroyStreamElement();
    this.streamingAvailable$.unsubscribe();
    // this.streamingAvailable$.complete();
    this.streamingAvailable$ = new Subject<boolean>();
    this.logger.logInfo('MWService', LoggingService.LogColors.Purple, 'End request to stop streaming session');
  }

  stopPresenterScreenSession() {
    this.onDisconnected();
  }

  checkForAvailableSession(modelId: string): Observable<any> {
    const monkeyWayConfig = this.cfg.carConfigList.find(
      (x: any) => x.modelId === modelId
    );

    if (!monkeyWayConfig) {
      // TODO: need to adjust correct response
      return of(400);
    }
    const url = `${monkeyWayConfig.baseUrl}/api/v1/session/check`;
    const headers = new HttpHeaders()
      .set("Authorization", `ApiKey ${monkeyWayConfig.apiKey}`)
      .set("Content-Type", "application/json")
      .set("X-AppEnvId", monkeyWayConfig.appEnvId);

    return this.http
      .post(url, {}, { headers, observe: "response" })
      .pipe(map((response: HttpResponse<any>) => response.status));
  }

  private onConnected(): void {
    this.logger.logInfoWithSessionStorage('MWService', LoggingService.LogColors.Purple, 'OnConnected - Connected to streaming service');
    this.streamingAvailable$.next(true);
    // this.streamingAvailable$.complete();
    this.streamControlSubs$.next(true);
  }

  private onDisconnected(): void {
    this.logger.logInfoWithSessionStorage('MWService', LoggingService.LogColors.Purple, 'OnDisconnected - Disconnected from streaming service');
    if (this.streamControlSubs$) {
      this.streamControlSubs$.next(false);
    }
    this.streamingAvailable$.next(false);
    this.streamingService?.stop();
  }

  public getStreamInfo() {
    return this._streamInfo;
  }
}
