import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ConfigurationProvider } from "src/app/core/services/configuration.provider";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setCountry } from "src/app/state/actions";
import { Country } from "../../core/models/Country";
import { LoggingService } from "src/app/core/services/logging.service";

@Injectable({
  providedIn: "root",
})
export class InvalidLocaleGuard implements CanActivate {
  constructor(
    private router: Router,
    private cfg: ConfigurationProvider,
    private store: Store<FerrariState>,
    private logger: LoggingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'GUARD - check if locale in URL is valid');

    const locale = route.paramMap.get("locale");

    if (!!locale) {
      const languageCode = locale.split("_")[0];
      const countryCode = locale.split("_")[1];

      if (!!languageCode) {
        const validLanguage = this.cfg.languages.find((p) => p.code === languageCode);
        if (!!validLanguage) {
          this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Language is valid');
        } else {
          this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Language is not valid, redirect to 404 page');
          this.router.navigateByUrl("/404");
        }
      } else {
        this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Language is not valid, redirect to 404 page');
        this.router.navigateByUrl("/404");
      }

      if (!!countryCode) {
        const validCountry = this.cfg.countries.find((p) => p.code === countryCode);
        if (!!validCountry) {
          this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Country is valid');
          this.store.dispatch(setCountry({ country: validCountry }));
        } else {
          if (["en","it","es","fr","de","jp","cn"].includes(countryCode)) {
            const urlSegments = state.url.split(`/${locale}/`);
            const correctedCountry = this.cfg.countries.find((p) => p.code === countryCode.toUpperCase());
            if (!!correctedCountry) {
              const correctedURL = urlSegments[1]
              ? `/${languageCode}_${correctedCountry.code}/${urlSegments[1]}`
              : `/${languageCode}_${correctedCountry.code}`;
              this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Country is valid but lowercase, redirect to correct country code');
              this.router.navigateByUrl(correctedURL);
            } else {
              this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Country is not valid, redirect to 404 page');
              this.router.navigateByUrl("/404");
            }
          } else {
            this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Country is not valid, redirect to 404 page');
            this.router.navigateByUrl("/404");
          }
        }
      } else {
        this.logger.logInfo('InvalidLocaleGuard', LoggingService.LogColors.Blue, 'Country is not valid, redirect to 404 page');
        this.router.navigateByUrl("/404");
      }
    }

    return true;
  }
}
