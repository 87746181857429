
// auth-interceptor.service.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthCodeInterceptorService implements HttpInterceptor {
  constructor(
    public sessionStorage: SessionStorageService,
  ){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the authentication token from wherever it's stored (e.g., local storage)
     const authorizationCode = this.sessionStorage.authorization_code;
     const refreshToken = this.sessionStorage.refresh_token;
    // console.log('authorizationCode', authorizationCode);
    // console.log('refreshToken', refreshToken);
     // Clone the request and add the authorization header if the token exists
     if (request.url.includes('gorillastreaming')) {
      return next.handle(request);
     } else if (authorizationCode && refreshToken) {
      const headers = new HttpHeaders({
        'authorization_code': this.sessionStorage.authorization_code + "",
        'refresh_token': this.sessionStorage.refresh_token + ""
      });
      const authReq = request.clone({headers});
       return next.handle(authReq);
     } else if (authorizationCode && !refreshToken){
      const authReq = request.clone({
        headers: request.headers.set('authorization_code', `${authorizationCode}`),
      });
      return next.handle(authReq);
     } else if (refreshToken && !authorizationCode){
      const authReq = request.clone({
        headers: request.headers.set('refresh_token', `${refreshToken}`),
      });
      return next.handle(authReq);
     }

     // If no token, just pass the request along
     return next.handle(request);

     /*
     .pipe(
      catchError((error) => {
        //console.log('intercept - catchError');
        //console.log('intercept - error', error);
        if (error.status === 401) {
          //sbianco
          //console.log('intercept - 401 - catchError');
          //console.log('intercept - 401 - error', error);
          this.sessionStorage.removeKeyFromSession("authorizationCode");
          this.sessionStorage.removeKeyFromSession("refreshToken");
          // Redirect to external page
          let loginObj = environment.loginConfig;
          window.location.href = loginObj.url + "?p=" + loginObj.p + "&client_id=" + loginObj.clientId + "&nonce=" + loginObj.nonce + "&redirect_uri=" + loginObj.redirectUri + "&scope=" + loginObj.scopes[0] + "&response_type=" + loginObj.responseType;

        }
        return throwError(error);
      })
    );

    */
   }

  }

