import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, catchError, map } from 'rxjs/operators';
import { Api } from 'src/app/core/services/_api';
import { LoggingService } from 'src/app/core/services/logging.service';


@Injectable({
  providedIn: 'root'
})
export class InvalidFerrariCodeGuard implements CanActivate {

  constructor(
    private api: Api,
    private logger: LoggingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.logger.logInfo('InvalidFerrariCodeGuard', LoggingService.LogColors.Blue, 'GUARD - InvalidFerrariCodeGuard');

    const ferrariCode = next.paramMap.get('ferrariCode');

    if (ferrariCode){
      return this.api.loadConfiguration(ferrariCode).pipe(
        map(p => !!p)
      );
    } else {
      return of(false);
    }
  }
}
