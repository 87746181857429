import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { Api } from 'src/app/core/services/_api';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Car } from 'src/app/core/models/Car';
import { HttpClient } from '@angular/common/http';
import { ConfigurationProvider } from 'src/app/core/services/configuration.provider';
import { LoggingService } from 'src/app/core/services/logging.service';


@Injectable({
  providedIn: 'root'
})
export class ActiveCarGuard implements CanActivate {

  constructor(
    private router: Router,
    private util: UtilityService,
    private http: HttpClient,
    private cfg: ConfigurationProvider,
    private logger: LoggingService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.logger.logInfo('ActiveCarGuard', LoggingService.LogColors.Blue, 'GUARD - check if selected car is active');
      var carId = next.paramMap.get("carModelId");
      this.logger.logInfo('ActiveCarGuard', LoggingService.LogColors.Blue, 'Car model id retrieved from URL:', carId);

      if (!carId) {
        this.logger.logInfo('ActiveCarGuard', LoggingService.LogColors.Blue, 'Car model is not valid, redirect to 404 page');
        this.router.navigateByUrl("/404");
      }

      let modelsUrl = '';
      if (this.cfg.isChina) {
        modelsUrl = `/rt-assets/data/china-models.json`;
      } else if (this.cfg.isMuseum) {
        modelsUrl = `/rt-assets/data/museum-models.json`;
      } else {
        modelsUrl = `/rt-assets/data/models.json`;
      }

      this.logger.logInfo('ActiveCarGuard', LoggingService.LogColors.Blue, 'Retrieve active car models from list');
      return this.http
      .get<any[]>(modelsUrl)
      .pipe(
        map((cars) => {
          const carsList = cars.filter((f) => f.active).map((car) => new Car(car));
          const selectedCar = carsList.find((c) => c.id == carId);
          if (!selectedCar || !selectedCar.active) {
            this.logger.logInfo('ActiveCarGuard', LoggingService.LogColors.Blue, 'Car model is not valid, redirect to 404 page');
            this.router.navigateByUrl("/404");
          }
          return selectedCar ? selectedCar.active : false;
        })
      );
  }
}
