import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable, PLATFORM_ID, Inject, Optional } from "@angular/core";
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ConfigurationProvider } from "./configuration.provider";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root",
})
export class UniversalTranslateLoaderService {
  TRANSLATIONS_FILE = makeStateKey("TRANSLATIONS_FILE");
  constructor(
    private ts: TransferState,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string,
    private cfg: ConfigurationProvider,
    private logger: LoggingService
  ) {}

  public getTranslation(lang: string) {
    // it should always get browser and local files for SSR we use TranslateServerLoader
    //this.logger.logInfo('UniversalTranslateLoaderService', LoggingService.LogColors.Blue, 'Language:', lang);
    // if (isPlatformBrowser(this.platformId)) {
    //   this.ts.set<any>(
    //     this.TRANSLATIONS_FILE,
    //     // require(`../../../rt-assets/i18n/${lang}.json`)
    //     require(`${this.cfg.cloudFrontUrl}/i18n/${lang}.json`)
    //   );
    //   return of(this.ts.get(this.TRANSLATIONS_FILE, null));

    // } else {
      const file_path = `${this.cfg.cloudFrontUrl}${this.cfg.isMuseum ? "/rt-assets" : ""}/i18n/${lang}.json`;
      //this.logger.logInfo('UniversalTranslateLoaderService', LoggingService.LogColors.Blue, 'here the path to read json from server:', file_path);
      return this.http.get(file_path).pipe(
        map((jsonFile) => {
          this.ts.set<any>(this.TRANSLATIONS_FILE, jsonFile);
          return this.ts.get(this.TRANSLATIONS_FILE, null);
        })
      );
    // }
  }
}

export function translateBrowserLoaderFactory(
  transferState: TransferState,
  http: HttpClient,
  platformId: any,
  cfg: ConfigurationProvider,
  logger: LoggingService
) {
  return new UniversalTranslateLoaderService(transferState, http, platformId, cfg, logger);
}
