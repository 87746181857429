import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Subscription } from "rxjs";
import { UtilityService } from "./utility.service";
import { Car } from "../models/Car";
import { FerrariState } from "src/app/shared/application-interfaces";
import { ConfigurationProvider } from "./configuration.provider";
import { isPlatformServer } from "@angular/common";
import { ConfigurationLevel, Option } from "../models/configuration-meta";
import { Preconfiguration } from "../models/Preconfiguration";
import { ActivatedRoute } from "@angular/router";
import { SaveResponse } from "../models/SaveResponse";
import { OptionSelection } from "../models/vehicle-config";
import { LoggingService } from "./logging.service";

function _initGA(GTM_id: string): any {
  return new Promise((resolve, _) => {
    // tslint:disable-next-line: typedef
    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      const f = d.getElementsByTagName(s)[0],
        // tslint:disable-next-line: triple-equals
        j: any = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
        if (f && j){
            j["async"] = true;
            j["src"] = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode?.insertBefore(j, f);
        }
      resolve(void 0);
    })(window, document, "script", "dataLayer", GTM_id);
  });
}

const DEFAULT_CAR_MODEL = "purosangue";

export enum ConfiguratorType {
  WEBCC_REALTIMERENDER = 'webcc_realtimerender',
  WEBCC_STREAMING = 'webcc_streaming',
  UNDEFINED = 'no_value'
}

export enum PackageType {
  DEFAULT = 'basic configuration',
  DESIGN = 'design pack configuration',
  FIORANO = 'fiorano pack configuration',
  UNDEFINED = 'no_value'
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private allCarModels: Car[] = [];
  private currentModel: Car | undefined;
  private currentPackage: string | undefined;
  private currentIdConfiguration: string | undefined;
  private selectedPreconfig: Preconfiguration | undefined;
  private currentType: ConfiguratorType | undefined;
  private ferrariCodeSaved: string | undefined;
  private isStreaming: boolean | undefined;
  private sessionId: string | undefined;
  private country: string | undefined;
  private isLoadTimeRegistered = false;
  private NO_VALUE = 'no_value';
  private GTM_id = "";
  private isChina = false;
  private lastVisitedPage: string | undefined;
  private isMuseum: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<FerrariState>,
    private cfg: ConfigurationProvider,
    private util: UtilityService,
    private activatedRoute: ActivatedRoute,
    private logger: LoggingService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isMuseum = this.cfg.isMuseum;
    this.isChina = this.cfg.isChina;
    if (!this.isEnabled()) {
      return;
    }
    this.GTM_id = this.cfg.gtmId;
    this.util.cars.subscribe((cars) => this.allCarModels = cars);
    this.subscription.add(this.store.select((p) => p.data.car).subscribe((car) => {
      this.currentModel = car;
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, "Update car:", { car });
    }));
    this.subscription.add(this.store.select((p) => p.data.packageId).subscribe((carPackage) => {
      const carPackageType = carPackage? carPackage.toUpperCase() as keyof typeof PackageType : 'UNDEFINED';
      this.currentPackage = PackageType[carPackageType]
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, "Update package:", { carPackage });
    }));
    this.subscription.add(this.store.select((p) => p.data.preconfiguration).subscribe((preconfig) => {
      this.selectedPreconfig = preconfig;
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, "Update preconfiguration:", { preconfig });
    }));
    this.subscription.add(this.store.select((p) => p.data.ferrariCode).subscribe((ferrariCode) => {
      this.ferrariCodeSaved = ferrariCode;
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, "Update ferrariCode:", { ferrariCode });
    }));
    this.subscription.add(combineLatest([
      this.store.select((p) => p.ui.isStreaming),
      this.store.select((p) => p.ui.sessionIdStreaming),
      this.activatedRoute.queryParams
    ]).subscribe(([isStreaming, sessionId, queryParams]) => {
      this.isStreaming = isStreaming;
      this.currentIdConfiguration = queryParams['configuration'];
      if (!!this.currentIdConfiguration) {
        this.currentType = !this.isStreaming ? ConfiguratorType.WEBCC_REALTIMERENDER : ConfiguratorType.WEBCC_STREAMING;
        this.sessionId = sessionId;
      } else {
        this.currentType = ConfiguratorType.UNDEFINED;
        this.sessionId = this.NO_VALUE;
      }
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, "Update isStreaming, sessionId and queryParams:", { isStreaming, sessionId, queryParams });
    }));
    this.init();
  }

  public init() {
    if (!this.isEnabled()) {
      return;
    }

    this.push(
      {
        'page': {
          'group': 'car configurator',
          'test': 'A',
        },
        'configurator': {
          'type': this.currentType || this.NO_VALUE,
          'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
          'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
          'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        },
        'car': {
          'code': this.currentModel?.code || this.NO_VALUE,
          'model': this.currentModel?.name || this.NO_VALUE,
        },
      }
    )
    // _initGA(this.GTM_id).then((_: any) => {

    // });
  }

  public pageChanged(currentPageUrl: string, nextPageUrl: string, nextPageTitle: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'pageChanged',
      'page': {
        'url': nextPageUrl || this.NO_VALUE,
        'title': nextPageTitle || this.NO_VALUE,
      },
      'previous_page': {
        'url': currentPageUrl || this.NO_VALUE,
      },
      'configurator': {
        'configuration_code': this.ferrariCodeSaved || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      },
    });
  }

  public clickItem(itemText: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'click',
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE
      },
      'event_data': {
        'click_text': itemText || this.NO_VALUE,
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      },
    });
  }

  public loadConfiguration(ferrariCode: string, carModel: Car, carPackage: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'loadConfiguration',
      'configurator': {
        'configuration_code': ferrariCode || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': carPackage || this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': carModel.code || this.currentModel?.code || this.NO_VALUE,
        'model': carModel.name || this.currentModel?.name || this.NO_VALUE,
      },
    });
  }

  public carConfiguratorLoaded(loadTime: number) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'carConfiguratorLoaded',
      'event_data': {
        'load_time': loadTime
      },
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public selectPackage(carPackage: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    const pkg = carPackage.toUpperCase() as keyof typeof PackageType || 'UNDEFINED';
    const selectedPackage = PackageType[pkg]
    this.push({
      'event': 'selectPackage',
      'configurator': {
        'package_setting': selectedPackage || this.NO_VALUE,
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public selectPreconfiguration(preconfig: Preconfiguration) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    const preconfigCar = this.allCarModels.find((car: Car) => car.code?.toLowerCase() === preconfig.model.toLowerCase());
    const pkg = preconfig.packageType.toUpperCase() as keyof typeof PackageType || 'UNDEFINED';
    const selectedPackage = PackageType[pkg]
    this.push({
      'event': 'selectPreconfiguration',
      'configurator': {
        'package_setting': selectedPackage || this.NO_VALUE,
        'pre_configuration': preconfig.name || this.NO_VALUE,
      },
      'car': {
        'code': preconfigCar?.code || this.currentModel?.code || this.NO_VALUE,
        'model': preconfigCar?.name || this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public openOption(optionStepName: string, optionListName: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'openOption',
      'event_data': {
        'option_step': optionStepName || this.NO_VALUE,
        'option_list': optionListName || this.NO_VALUE
      },
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public selectOption(configLevel: ConfigurationLevel | null | undefined, option: OptionSelection | undefined, trunk: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    if (option && configLevel) {
      const rootPath = configLevel.getPathToRoot();
      let macroSection = this.NO_VALUE;
      let section = this.NO_VALUE;
      if (!!rootPath) {
        if (rootPath.length === 3) {
          // we are in seat section
          macroSection = rootPath[0];
          section = rootPath[2];
        } else {
          macroSection = rootPath[0];
          section = rootPath[1];
        }
      }
      this.push({
        'event': 'selectOption',
        'event_data': {
          'option_step': macroSection || this.NO_VALUE,
          'option_list': section || this.NO_VALUE,
          'option_name': option.optionId || this.NO_VALUE,
          'option_value': !!option.variantId ? `${option.optionId}_${option.variantId}` : `${option.optionId}` || this.NO_VALUE,
        },
        'configurator': {
          'type': this.currentType || this.NO_VALUE,
          'configuration_id': trunk || this.NO_VALUE,
          'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
          'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
          'session_id': this.sessionId || this.NO_VALUE
        },
        'car': {
          'code': this.currentModel?.code || this.NO_VALUE,
          'model': this.currentModel?.name || this.NO_VALUE,
        }
      });
    }
  }

  public removeOption(configLevel: ConfigurationLevel | null | undefined, option: OptionSelection | undefined, trunk: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    if (option && configLevel) {
      const rootPath = configLevel.getPathToRoot();
      let macroSection = this.NO_VALUE;
      let section = this.NO_VALUE;
      if (!!rootPath) {
        if (rootPath.length === 3) {
          // we are in seat section
          macroSection = rootPath[0];
          section = rootPath[2];
        } else {
          macroSection = rootPath[0];
          section = rootPath[1];
        }
      }
      this.push({
        'event': 'removeOption',
        'event_data': {
          'option_step': macroSection || this.NO_VALUE,
          'option_list': section || this.NO_VALUE,
          'option_name': option.optionId || this.NO_VALUE,
          'option_value': !!option.variantId ? `${option.optionId}_${option.variantId}` : `${option.optionId}` || this.NO_VALUE,
        },
        'configurator': {
          'type': this.currentType || this.NO_VALUE,
          'configuration_id': trunk || this.NO_VALUE,
          'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
          'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
          'session_id': this.sessionId || this.NO_VALUE
        },
        'car': {
          'code': this.currentModel?.code || this.NO_VALUE,
          'model': this.currentModel?.name || this.NO_VALUE,
        }
      });
    }
  }

  public selectOptionCC(optionType: string, value: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'selectCarConfiguratorOption',
      'event_data': {
        'option_name': optionType,
        'option_value': value
      },
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public imageRender(loadingTime: number, renderType: string, imageName: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'imageRender',
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'event_data': {
        'render_type': renderType || this.NO_VALUE,
        'image': imageName || this.NO_VALUE,
        'loading_time': loadingTime
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public lead(saveResponse: SaveResponse) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'lead',
      'configurator': {
        'configuration_code': saveResponse.configuration_code || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': saveResponse.configuration_id || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public saveConfiguratioOnMyFerrari(saveResponse: SaveResponse) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'saveConfiguration',
      'configurator': {
        'configuration_code': saveResponse.configuration_code || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': saveResponse.configuration_id || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public generateQrCode(code: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'generateQrCode',
      'configurator': {
        'configuration_code': code || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public getYourConfigurationCode(code: string, configId: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'getYourCode',
      'configurator': {
        'configuration_code': code || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': configId || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public share(socialType: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'share',
      'configurator': {
        'configuration_code': this.ferrariCodeSaved || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'event_data': {
        'option_value': socialType || this.NO_VALUE,
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public download() {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'download',
      'configurator': {
        'configuration_code': this.ferrariCodeSaved || this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.currentPackage || this.NO_VALUE,
        'pre_configuration': this.selectedPreconfig?.name || this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public firstAccess() {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'firstAccess',
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public error(descriptionError: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'error',
      'event_data': {
        'description': descriptionError || this.NO_VALUE,
      },
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public timeout(descriptionTimeout: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'timeout',
      'event_data': {
        'description': descriptionTimeout || this.NO_VALUE,
      },
      'configurator': {
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.isMuseum ? this.NO_VALUE : (this.currentPackage || this.NO_VALUE),
        'pre_configuration': this.isMuseum ? this.NO_VALUE : (this.selectedPreconfig?.name || this.NO_VALUE),
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  public createUniquePerformanceMark(name: string) {
    if (!!performance.getEntriesByType('mark').find(mark => mark.name === name)) {
      performance.clearMarks(name);
    }
    performance.mark(name);
  }

  public calculateDurationEvent(eventType: string): number {
    const entriesMeasures = performance.getEntriesByType("measure");
    const measure = entriesMeasures.find(x => x.name === eventType);
    if (!!measure) {
      return Math.trunc(measure.duration);
    }
    return 0;
  }

  /*
  * Specific for Museum.
  */
  public selectModel(carModel: Car) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'selectModel',
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': carModel.code || this.currentModel?.code || this.NO_VALUE,
        'model': carModel.name || this.currentModel?.name || this.NO_VALUE,
      }
    });
  }


  /*
  * Specific for Museum.
  */
  public startConfiguration() {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'startConfiguration',
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    });
  }

  /*
  * Specific for Museum.
  */
  public generateQrCodeMuseum(code: string, openedOrClosed: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'generateQrCode',
      'event_data': {
        'option_value': openedOrClosed
      },
      'configurator': {
        'configuration_code': code,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    })
  }

  /*
  * Specific for Museum.
  */
  public areYouAvailable(step: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'areYouAvailable',
      'event_data': {
        'option_value': step
      },
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    })
  }

  /*
  * Specific for Museum.
  */
  public errorPopUp(step: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'errorPopUp',
      'event_data': {
        'option_value': step
      },
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    })
  }

  /*
  * Specific for Museum.
  */
  public restart(step: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    this.push({
      'event': 'restart',
      'event_data': {
        'option_value': step
      },
      'configurator': {
        'configuration_code': this.NO_VALUE,
        'type': this.currentType || this.NO_VALUE,
        'configuration_id': this.currentIdConfiguration || this.NO_VALUE,
        'package_setting': this.NO_VALUE,
        'pre_configuration': this.NO_VALUE,
        'session_id': this.sessionId || this.NO_VALUE
      },
      'car': {
        'code': this.currentModel?.code || this.NO_VALUE,
        'model': this.currentModel?.name || this.NO_VALUE,
      }
    })
  }


  // -------------------------------- OLD --------------------------------------

  public visitedNewPage(page: any) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;
    const snakeCasePage = this.convertToSnakeCase(page);
    if (snakeCasePage !== this.lastVisitedPage) {
      this.push({
        'page': snakeCasePage,
        'carModel': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
        'event': 'dummyPage'
      });
      this.lastVisitedPage = snakeCasePage;
    }
  }

  public navigateToStep(step: string) {
    if (!this.isEnabled()) {
      return;
    }
    if (isPlatformServer(this.platformId)) return;

    this.push({
      'event': 'dummyPage',
      'carModel': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
      'page': step,
      'configuration': 'NEW',
      'configurationId': '',
    });
  }

  public selectView(view: string, isFullscreen: boolean) {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    this.push({
      'event': 'selectView',
      'model': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
      'carModel': this.currentModel ? this.currentModel.modelId : DEFAULT_CAR_MODEL,
      'viewSelected': view,
      'fullscreen': isFullscreen ? 'On' : 'Off',
    });
  }

  public selectOptionOld(option: Option | undefined, image: string, loadingTime: string) {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }
    if (option && option.values){
        const rootPath = option.getPathToRoot();
        const optionList = rootPath[rootPath.length-2] || "";
        const step = rootPath[0] || "";
        option.values.forEach(v => {
          this.push({
            'event': 'selectOption',
            'model': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
            'carModel': this.currentModel ? this.currentModel.modelId : DEFAULT_CAR_MODEL,
            'option': option.id,
            'optionList': optionList,
            'step': step,
            'value': !!v.variantId ? `${v.optionId}_${v.variantId}` : `${v.optionId}`,
            'image': image,
            'loadingTime': loadingTime
          });
        });
    }

  }

  public selectNewOrPreviousConfig(event: string = 'newConfiguration', modelName?: string) {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    this.push({
      'event': event,
      'carModel': modelName ? modelName : DEFAULT_CAR_MODEL,
      'model': modelName ? modelName : DEFAULT_CAR_MODEL,
    });
  }

  public selectCar(model: Car) {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    this.push({
      'event': 'selectCar',
      'carModel': model ? model.modelId : DEFAULT_CAR_MODEL,
      'model': model ? model.name : DEFAULT_CAR_MODEL,
    });
  }

  public downloadPDF() {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    this.push({
      'event': 'downloadPDF',
      'model': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
    });
  }

  public loadConfigurationOLD(ferrariCode: string, modelName: string) {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    this.push({
      'event': 'loadConfiguration',
      'ferrariCode': ferrariCode,
      'model': modelName,
      'carModel': modelName
    });
  }

  public pushToHistoryChange(url: string) {
    if (!this.isEnabled()) {
      return;
    }
    window.history.replaceState(
      { id: "gtm.historyChange", history: "gtm.historyChange", url: url },
      "gtm.historyChange",
      url
    );
    // window.history.pushState({id: 'gtm.historyChange', history: 'gtm.historyChange', url: url}, "gtm.historyChange", url);
  }

  public renderDuration(sliderClick: boolean, loadingTime: string, imageName: string, ccType: string = "new_smart") {
    if (isPlatformServer(this.platformId)) return;
    if (!this.isEnabled()) {
      return;
    }

    if (this.isLoadTimeRegistered) {
      return;
    }

    this.push({
      'event': 'imageRender',
      'loadingTime': loadingTime,
      'sliderClick': sliderClick,
      'image': imageName,
      'model': this.currentModel ? this.currentModel.name : DEFAULT_CAR_MODEL,
      'carModel': this.currentModel ? this.currentModel.modelId : DEFAULT_CAR_MODEL,
      'ccType': ccType
    });

    this.isLoadTimeRegistered = true;

    setTimeout(() => {
      // to avoid dupplicate
      this.isLoadTimeRegistered = false;
    }, 1000)
  }

//   public setLoadingTime(loadingTime: number) {
//     if (!this.isEnabled()) {
//       return;
//     }
//     if (this.isLoadTimeRegistered) {
//       return;
//     }
//     this.push({
//       event: "carConfiguratorLoaded",
//       loadingTime: loadingTime,
//     });
//     this.isLoadTimeRegistered = true;
//   }

  private push(data: any) {
    if (!this.isEnabled()) {
      return;
    }
    window['dataLayer'].push(data);

    if (!data.event) {
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, `[EVENT START DATALAYER]`, data);
    } else {
      this.logger.logAnalytics("Analytics", LoggingService.LogColors.Pink, `[EVENT ${data.event}]`, data);
    }

  }

  private convertToSnakeCase(input: string): string {
    return input.toLocaleLowerCase().split(" ").join("_");
  }

  private isEnabled() {
    // If ferrari decides to disable it for some third party programs like weChat we should do it here
    return true;
  }
}
