import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep, map, tap } from "lodash";
import { Observable, of, combineLatest, switchMap, forkJoin } from "rxjs";
import { ModelConfigurationService } from "../../core/services/model-configuration.service";
import { UtilityService } from "../../core/services/utility.service";
import { FerrariState } from "../../shared/application-interfaces";
import { setCarPackage, setSelectedCar, setSelectedPreconfiguration } from "../../state/actions";
import { filter } from "rxjs/operators";
import { VehicleConfigurationMeta } from "src/app/core/models/configuration-meta";
import { ConfigurationManagerService } from "src/app/core/services/configuration-manager.service";
import { Preconfiguration } from "src/app/core/models/Preconfiguration";
import { LoggingService } from "src/app/core/services/logging.service";

@Injectable({
  providedIn: "root",
})
export class ModelSelectionResolver implements Resolve<any> {
  constructor(
    private modelConfig: ModelConfigurationService,
    private util: UtilityService,
    private route: ActivatedRoute,
    private store: Store<FerrariState>,
    private cm: ConfigurationManagerService,
    private logger: LoggingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.logger.logInfo('ModelSelectionResolver', LoggingService.LogColors.Blue, 'RESOLVER - Retrieve info about selected car model');
    const carModelId = route.paramMap.get("carModelId") || null;
    const packageId = route.paramMap.get("packageId") || undefined;
    const configId = route.queryParamMap.get('configuration') || undefined;
    if (!configId) {
      this.store.dispatch(
        setSelectedPreconfiguration({ preconfiguration: new Preconfiguration({}) })
      );
    }
    const car$ = this.util.getCarModel(carModelId);
    if (!!car$) {
      combineLatest([car$, this.store.select((p) => p.data.country)]).pipe(filter(f => !!f[0])).subscribe(
        (data) => {
          const car = data[0];
          const country = data[1];
          if (!!car) {
            const vehicleInfo = Object.assign({}, car);
            vehicleInfo.marketId = country.marketIds[0] || "00";

            this.util.vehicleMarketMapping
              .pipe(
                switchMap((m) => {
                  if (m.length === 0) {
                    this.util.vehicleMarketMap(car.id);
                  }
                  return this.util.vehicleMarketMapping.asObservable();
                })
              )
              .subscribe((mapping) => {
                if (mapping.length > 0) {
                  const vehicleMarketObj = mapping.find(
                    (x: any) => x.marketId === vehicleInfo.marketId
                  );
                  if (!!vehicleMarketObj) {
                    vehicleInfo.vehicleId = vehicleMarketObj.vehicleId;
                  }

                  this.store
                    .select((p) => p.data.vehicleConfigMeta)
                    .subscribe(
                      (
                        vehicleConfigMeta: VehicleConfigurationMeta | undefined
                      ) => {
                        const observables: Observable<VehicleConfigurationMeta>[] =
                          [];
                        // if (!!vehicleConfigMeta){
                        //   observables.push(of(vehicleConfigMeta));
                        // } else {
                        if (
                          !!vehicleConfigMeta &&
                          vehicleConfigMeta.id !== vehicleConfigMeta.id
                        ) {
                          observables.push(
                            this.modelConfig.getVehicleConfigurationMeta(
                              vehicleInfo
                            )
                          );
                        } else if (!vehicleConfigMeta) {
                          observables.push(
                            this.modelConfig.getVehicleConfigurationMeta(
                              vehicleInfo
                            )
                          );
                        } else {
                          observables.push(of(vehicleConfigMeta));
                        }
                        // }
                        forkJoin(observables)
                          .pipe(filter((f) => !!f))
                          .subscribe((data) => {
                            // the calls now done if from store or call BE to get json file
                            if (!!vehicleInfo) {
                              this.store.dispatch(
                                setSelectedCar({ car: vehicleInfo })
                              );
                              this.store.dispatch(
                                setCarPackage({ packageId })
                              );
                              // const carId = vehicleInfo.id || "";
                              // const meta = cloneDeep(data[0]);
                              // const configlevel = cloneDeep(
                              //   meta.configuration.configHierarchy
                              // );

                              // this.util
                              //   .applyPackage(carId, packageId, configlevel)
                              //   .then(() => {
                              //     meta.configuration.configHierarchy = cloneDeep(configlevel);
                              //     this.cm.setVehicleMeta(meta);
                              //     console.log(
                              //       "here configmeta after changes of package: ",
                              //       meta
                              //     );
                              //   });
                            }
                          });
                      }
                    );
                }
              });
          }
        }
      );
    }
    return of("");
  }
}
