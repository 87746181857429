import { Injectable } from "@angular/core";
import { MonkeyWayService } from "./monkey-way.service";
import { StateLineConfigService } from "../_stateline-client/state-line-config";
import { StateLineClientService } from "../_stateline-client/state-line-client.service";
import { ApplicationStateService } from "../_stateline-client/application-state.service";
import {
  COMMAND_RESET_SCENE,
} from "../_stateline-client/application-commands";
import {
  BehaviorSubject,
  map,
  of,
  switchMap,
  take,
  tap
} from "rxjs";
import { UiStateService } from "../_stateline-client/ui-state.service";
import {
  ApplicationInfoService,
} from "../_stateline-client/application-info.service";
import { UtilityService } from "./utility.service";
import {
  STATE_CAR_SELECTION,
  STATE_EXPLORE,
} from "../_stateline-client/state-machine-states";
import { ApplicationUiContextService } from "../_stateline-client/application-ui-context.service";
import { FerrariState } from "src/app/shared/application-interfaces";
import { Store } from "@ngrx/store";
import { ConfigurationManagerService } from "./configuration-manager.service";
import { SceneStateService } from "../_stateline-client/scene-state.service";
import { Car } from "../models/Car";
import { filter } from "lodash";
import { environment } from "src/environments/environment";
import { ConfigurationProvider } from "./configuration.provider";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root",
})
export class StreamingClient {
  private _streamingStatus: boolean = false;
  private _modelLoadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  get isStreamingStarted() {
    return this.streamingStatus;
  }

  set streamingStatus(value: boolean) {
    this._streamingStatus = value;
  }

  isModelLoaded() {
    return this._modelLoadingStatus$.asObservable();
  }

  setModelLoaded(value: boolean) {
    this._modelLoadingStatus$.next(value);
  }
  /**
   * start streaming session helper
   */
  constructor(
    private monkeyWay: MonkeyWayService,
    private wampConfig: StateLineConfigService,
    private stateLineClient: StateLineClientService,
    private applicationStateService: ApplicationStateService,
    private stateLineClientService: StateLineClientService,
    private uiState: UiStateService,
    private applicationInfoService: ApplicationInfoService,
    private util: UtilityService,
    private cm: ConfigurationManagerService,
    private sceneStateService: SceneStateService,
    private appuiContext: ApplicationUiContextService,
    private store: Store<FerrariState>,
    private cfg: ConfigurationProvider,
    private logger: LoggingService
  ) {}

  public hasActiveSession(): boolean {
    const info = this.monkeyWay.getStreamInfo();
    return !!info;
  }

  public async startStreaming(car: Car): Promise<any> {
    this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'Start subscription to MW streaming availability');
    return this.monkeyWay.streamingAvailable$.subscribe({
      next: (isAvailable) => {
        setTimeout(() => {
          if (!this.streamingStatus){
            this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'Streaming is not started yet, proceed to start');
            if (isAvailable) {
              this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'MW streaming is available, proceed to start');
              const streamInfo = this.monkeyWay.getStreamInfo();
              if (!!streamInfo) {
                this.streamingStatus = isAvailable;
                this.wampConfig.url = this.cfg.isMuseum ? (environment.streamingConfig.manualUrl || `${this.wampConfig.websocketProtocol}${streamInfo.specs["publicDomainName"]}/ws`) : `${this.wampConfig.websocketProtocol}${streamInfo.specs["publicDomainName"]}/ws`;
                // connect to global streaming in case of local fails (it need to change manually)
                // this.wampConfig.url = `wss://ferrari-streaming-15-161-54-166.solution.gorillastreaming.com/ws`;
                this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'Request Stateline initialization');
                this.stateLineClient.init();
                this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'Request Stateline session id');
                this.stateLineClient.getSessionId().subscribe((sessionId) => {
                  this.appuiContext.initMachineState();
                  this.applicationStateService.subscribeToCommandsInit();
                  this.applicationInfoService.subscribeToCommandsInit();
                  this.sceneStateService.initializeObservables();
                  this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'This is sessionId:', sessionId);
                  if (!!car.cwsId){
                    this.setInitialConfig(car.cwsId);
                  }
                });
              }
            } else {
              this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'MW streaming is not available at the moment');
            }
          } else {
            this.logger.logInfo('StreamingClient', LoggingService.LogColors.Green, 'Streaming is already started');
          }
        }, 10);
      },
      error: (err) => {
        this.logger.logError('StreamingClient', 'Error on MW request for streaming available. Details:', err);
      }
    });
  }


  private setInitialConfig(cwsId: string) {
    // TODO: remove timeout if not needed in new build
    // this.applicationStateService.handleServerCallInProgress(
    //   this.stateLineClientService.publishCommand({ type: COMMAND_RESET_SCENE })
    // );
    this.setModelLoaded(true);
    // setTimeout(() => {
    //   const chosenOptions = this.cm.mapToCobaOptions([]);
    //   this.applicationStateService
    //     .handleServerCallInProgress(
    //       this.stateLineClientService
    //         .publish(`mv.commands.select.${cwsId}`, {
    //           forceUpdate: true,
    //           productId: cwsId,
    //           chosenOptions,
    //         })
    //         .pipe(map(() => void 0))
    //     )
    //     .pipe(
    //       tap(() => {
    //         this.sceneStateService.notifyConfigurationChangeSent();
    //         this.setModelLoaded(true);
    //       })
    //     )
    //     .subscribe();
    // }, 1000);
  }

  public endStreaming() {
    this.stateLineClient.alreadyConnected = false;
    this.stateLineClient.destroy$.next();
    this.stateLineClient.destroy$.complete();
    this.stateLineClient.closeConnection();
  }

}
