<div class="close-top-modal" (click)="close()">
  <div class="ferrari-close"></div>
</div>
<div class="container-modal">
  <div class="modal-title">
    <span>{{ title | translate }}</span>
  </div>
  <div class="modal-description">
    <span [innerHTML]="description | translate"></span>
  </div>
</div>
