import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  PLATFORM_ID,
} from "@angular/core";
import { bootstrapApplication, TransferState } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app/app.component";
import { environment } from "./environments/environment";
import routes from "./app/router/routes";
import { ConfigurationProvider } from "./app/core/services/configuration.provider";
import { LocalStorageService } from "./app/core/services/local-storage.service";
import { UtilityService } from "./app/core/services/utility.service";
import { GlobalErrorHandlerService } from "./app/core/services/global-error-handler.service";
import { reducers } from "./app/state/reducers";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  provideEffects,
  provideNgxTranslate,
  provideStore,
  provideStoreDevtools,
} from "./app/shared/_modules/standalone-ngrx";
import { MaterialModule } from "./app/shared/_modules/material.module";
import { NavigationService } from "./app/core/services/navigation.service";
import { BrowserDetectorService } from "./app/core/services/browser-detector.service";
import { ModelConfigurationService } from "./app/core/services/model-configuration.service";
import { CommonService } from "./app/core/services/common.service";
import { Api } from "./app/core/services/_api";
import { ConfigurationManagerService } from "./app/core/services/configuration-manager.service";
import { DialogService } from "./app/core/services/dialog.service";
import { UserService } from "./app/core/services/user.service";
import { AnalyticsService } from "./app/core/services/analytics.service";
import { AnimationDialogService } from "./app/core/services/animation-dialog.service";
import { MetaService } from "./app/core/services/meta.service";
import { WampClientService } from "./app/core/_stateline-client/wamp.client.service";
import { StateLineClientService } from "./app/core/_stateline-client/state-line-client.service";
import { ApplicationStateService } from "./app/core/_stateline-client/application-state.service";
import { SceneStateService } from "./app/core/_stateline-client/scene-state.service";
import { UiStateService } from "./app/core/_stateline-client/ui-state.service";
import { ApplicationInfoService } from "./app/core/_stateline-client/application-info.service";
import { MonkeyWayService } from "./app/core/services/monkey-way.service";
import { StateLineConfigService } from "./app/core/_stateline-client/state-line-config";
import { ApplicationUiContextService } from "./app/core/_stateline-client/application-ui-context.service";
import { StreamingClient } from "./app/core/services/streaming-client.service";
// import { InactivityService } from "./app/core/services/inactivity.service";
import { SessionStorageService } from "./app/core/services/session-storage.service";
import { AvailabilityCheckService } from "./app/core/services/availability-check.service";
import { AutoRefreshService } from "./app/core/services/autoRefresh.service";
import { NgIdleService } from "./app/core/services/ng-idle.service";
import { LinkPrefetchService } from "./app/core/services/link-prefetch.service";
import { AuthCodeInterceptorService } from "./app/core/interceptors/auth-code-interceptor.service";
import { LoggingService } from "./app/core/services/logging.service";

if (environment.production) {
  enableProdMode();
}

export function initializeApp(linkPrefetchService: LinkPrefetchService) {
  return () => linkPrefetchService.prefetchData();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      RouterModule.forRoot(routes),
      MaterialModule,
      BrowserAnimationsModule,
      HttpClientModule,
      ConfigurationProvider,
      UtilityService,
      LocalStorageService,
      LoggingService,
      SessionStorageService,
      NavigationService,
      BrowserDetectorService,
      ModelConfigurationService,
      CommonService,
      Api,
      ConfigurationManagerService,
      DialogService,
      UserService,
      AnalyticsService,
      AnimationDialogService,
      MetaService,
      // streaming services
      WampClientService,
      StateLineClientService,
      ApplicationStateService,
      SceneStateService,
      UiStateService,
      ApplicationInfoService,
      StateLineConfigService,
      ApplicationUiContextService,
      StreamingClient,
      // InactivityService,
      AvailabilityCheckService,
      AutoRefreshService,


      MonkeyWayService,
      NgIdleService,
      LinkPrefetchService
    ),
    // configure NgRx modules
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideStore(reducers),
    // configure translate
    provideNgxTranslate(),
    // Error handling
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    // auth
    // provideRouter(routes, initialNavigation, withDebugTracing()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [LinkPrefetchService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthCodeInterceptorService, multi: true }
  ],
}).catch((err) => console.error(err));
