import { Injectable } from "@angular/core";
import { Locale } from "../models/Locale";
import { Language } from "../models/Language";

//------ DEBUGGING USING LOCAL STORAGE ------//
interface SessionLog {
	invoker: string;
	message: string;
	severity: string;
}
interface SessionTime {
  started: number;
  ended?: number;
  duration?: string;
}
interface Session {
  logs: SessionLog[];
  time: SessionTime;
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  public get locale(): Locale {
    return this.get("locale");
  }
  public set locale(value: Locale) {
    this.set("locale", value);
  }

  public get language(): Language {
    return this.get("language");
  }
  public set language(value: Language) {
    this.set("language", value);
  }

  public get credentials (): any {
    return this.get('credentials');
  }
  public set credentials (value: any) {
    this.set('credentials', value);
  }

  public get access_token (): any {
    return this.get('access_token');
  }
  public set access_token (value: any) {
    this.set('access_token', value);
  }

  public get acr_values(): any {
    return this.get('acr_values');
  }
  public set acr_values(value: any) {
    this.set('acr_values', value);
  }

  public get id_token (): any {
    return this.get('id_token');
  }
  public set id_token (value: any) {
    this.set('id_token', value);
  }

  public get refresh_token (): any {
    return this.get('refresh_token');
  }
  public set refresh_token (value: any) {
    this.set('refresh_token', value);
  }

  public get lastAction (): any {
    return this.get('userLastAction');
  }
  public set lastAction (value: any) {
    this.set('userLastAction', value);
  }

  public get isMuseum (): any {
    return this.get('isMuseum');
  }
  public set isMuseum (value: any) {
    this.set('isMuseum', value);
  }

  public get currentPage (): any {
    return this.get('currentPage');
  }
  public set currentPage (value: any) {
    this.set('currentPage', value);
  }

  public get previousSelectedCarMuseum (): any {
    return this.get('previousSelectedCarMuseum');
  }
  public set previousSelectedCarMuseum (value: any) {
    this.set('previousSelectedCarMuseum', value);
  }

  private get(key: string): any {
    if (
      typeof window === "undefined" ||
      typeof window.localStorage === "undefined"
    ) {
      return;
    }
    try {
      return JSON.parse(window.localStorage.getItem(key) || "");
    } catch (e) {
      // weird Safari issue in private mode, where LocalStorage is defined but throws error on access
      return;
    }
  }

  public remove(key: string){
    window.localStorage.removeItem(key);
  }

  private set(key: string, value: any): any {
    if (
      typeof window === "undefined" ||
      typeof window.localStorage === "undefined"
    ) {
      return;
    }
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return;
    } catch (e) {
      // weird Safari issue in private mode, where LocalStorage is defined but throws error on access
      return;
    }
  }

  //------ DEBUGGING USING LOCAL STORAGE ------//

  private maxSessions: number = 5;

  public logLevelEnabled(logLevel: string) {
    const logLevels: string = window.localStorage.getItem("logLevels") || "[]";
    const logLevelsArray: string[] = JSON.parse(logLevels);
    return logLevelsArray.includes(logLevel);
  }

  public addSessionLog(sessionLog: SessionLog) {
    if (!window.localStorage.getItem("sessions")) {
      window.localStorage.setItem("sessions", "[]");
    }
    let sessions: string = window.localStorage.getItem("sessions") || "[]";
    const sessionsArray: Session[] = JSON.parse(sessions);
    sessionsArray[sessionsArray.length-1].logs.push(sessionLog);
    window.localStorage.setItem("sessions", JSON.stringify(sessionsArray));
  }

  public startSession() {
    if (!window.localStorage.getItem("sessions")) {
      window.localStorage.setItem("sessions", "[]");
    }
    let sessions: string = window.localStorage.getItem("sessions") || "[]";
    const sessionsArray: Session[] = JSON.parse(sessions);
    if (sessionsArray.length > this.maxSessions) {
      sessionsArray.shift();
    }
    const session: Session = {
      time: {
        started: (new Date).getTime()
      },
      logs: []
    };
    sessionsArray.push(session);
    window.localStorage.setItem("sessions", JSON.stringify(sessionsArray));
  }

  public endSession() {
    let sessions: string = window.localStorage.getItem("sessions") || "[]";
    const sessionsArray: Session[] = JSON.parse(sessions);
    if (sessionsArray.length) {
      const lastSessionIndex = sessionsArray.length - 1;
      const lastSession: Session = sessionsArray[lastSessionIndex];
      lastSession.time.ended = (new Date).getTime();
      const duration: number = Math.floor(((lastSession.time.ended - lastSession.time.started) / 1000));
      lastSession.time.duration = `${duration} seconds`;
      window.localStorage.setItem("sessions", JSON.stringify(sessionsArray));
    }
  }
}
