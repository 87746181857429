import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { UtilityService } from "src/app/core/services/utility.service";
import { FerrariState } from "src/app/shared/application-interfaces";
import { setSelectedSection } from "src/app/state/actions";
import { VehicleConfig } from '../../core/models/vehicle-config';
import { Api } from "../../core/services/_api/index";
import { ConfigurationManagerService } from '../../core/services/configuration-manager.service';
import { NavigationService } from '../../core/services/navigation.service';
import { LoggingService } from "src/app/core/services/logging.service";

@Injectable({
  providedIn: "root",
})
export class VehicleConfigResolver implements Resolve<any> {
  constructor(
    private logger: LoggingService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.logger.logInfo('VehicleConfigResolver', LoggingService.LogColors.Blue, 'RESOLVER - VehicleConfigResolver');
    // console.log("[RESOLVER] VehicleConfigResolver state url:", state.url);
    // this.navService.addToHistory(state.url.split('/'));
    // const carModelId = route.paramMap.get("carModelId") || "";
    // const section = route.paramMap.get("section") || "";
    // this.store.dispatch(setSelectedSection({ section }));
    // const packageId = route.paramMap.get("packageId") || "";
    // const configHash = route.queryParamMap.get("configuration") || "";
    // const car$ = this.util.getCarModel(carModelId);
    // if (!!car$) {
    //   car$.subscribe((vehicleInfo) => {
    //     if (!!vehicleInfo){
    //     // all car info inside car$ from model resolver
    //     const vehicleConfig = new VehicleConfig(
    //         this.store,
    //         vehicleInfo?.marketId,
    //         vehicleInfo?.modelId,
    //         vehicleInfo?.vehicleId
    //       );
    //       this.api
    //         .read(carModelId, packageId, configHash)
    //         .subscribe(
    //             values => {
    //                 if (!!values) {
    //                     // we can retrive the selected options from configuration hash in url
    //                     values.forEach((val: any) => {
    //                         vehicleConfig.selectOption(val.optionId, val.variantId);
    //                     });
    //                     // this.config.cloneVehicleConfigManager(vehicleConfig);
    //                     this.config.vehicleConfig = vehicleConfig;
    //                     console.log('here the list of converted opts: ', this.config.vehicleConfig.getFullOptionList());
    //                     console.log('here the list of original opts: ', this.config.vehicleConfig.getSelectedOptions());
    //                 }
    //             },
    //             error => {
    //               // it could happen for preconfig that saved in different stage so we need to get opts from preconfig saved in store

    //             }
    //           );
    //     }
    //   });
      // we need to initialize vehicleConfig class and put in service
    // }
    return of("");
  }
}
