import { Injectable } from "@angular/core";
import {
  combineLatest,
  ConnectableObservable,
  forkJoin,
  map,
  Observable,
  of,
  publishReplay,
  ShareConfig,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs";
import {
  ConfigurationLevel,
  Option,
  OptionTypeMapper,
  VehicleConfigurationBuilder,
  VehicleConfigurationMeta,
} from "../models/configuration-meta";
import { VehicleInfo } from "../models/vehicle-config";
import { HttpClient } from "@angular/common/http";
import { FerrariState } from "../../shared/application-interfaces";
import { Store } from "@ngrx/store";
import { setVehicleMeta } from "src/app/state/actions";
import { ConfigurationProvider } from "./configuration.provider";
import { ConfigurationManagerService } from "./configuration-manager.service";
import { LoggingService } from "./logging.service";

declare class TextDecoder {
  constructor(encoding: string);
  decode(input: any): string;
}
interface OptionTypeMappingPayload {
  option_types: {
    colors: string[];
    thumbs: string[];
    lists: string[];
  };
}

@Injectable({
  providedIn: "root",
})
export class ModelConfigurationService {
  private readonly vehicleParser: VehicleConfigurationBuilder;

  private readonly vehicleConfigurationCache = new Map<
    string,
    Observable<VehicleConfigurationMeta>
  >();
  private readonly optionMappingCache = new Map<
    string,
    Observable<OptionTypeMapper>
  >();

  constructor(
    private http: HttpClient,
    private store: Store<FerrariState>,
    private cfg: ConfigurationProvider,
    private logger: LoggingService
  ) {
    this.vehicleParser = new VehicleConfigurationBuilder();
  }

  getVehicleConfigurationMeta(
    vehicleInfo: VehicleInfo
  ): Observable<VehicleConfigurationMeta> {
    if (vehicleInfo.id === "") {
      return of();
    }

    const cachedConfigObservable = this.vehicleConfigurationCache.get(
      vehicleInfo.vehicleId
    );
    if (!cachedConfigObservable) {
      // create observable that caches result
      // const x = <Observable<VehicleConfigurationMeta>>this.store
      //   .select((p) => p.data.car)
      //   .pipe(
      //     withLatestFrom(this.fetchGuiStructure(vehicleInfo)),
      //     switchMap((data) => {
      //       const vehicleInfo = data[0];
      //       const guiStructure = data[1];
      //       const vehicleConfigurationMeta =
      //         this.vehicleParser.buildVehicleConfigurationMeta(
      //           guiStructure,
      //           vehicleInfo
      //         );
      //       console.log("this is vehicleMeta : ", vehicleConfigurationMeta);
      //       // this.configManager.setVehicleMeta(vehicleConfigurationMeta);
      //       // const test = vehicleConfigurationMeta.getOptionFromLevel(
      //       //   [{ optionId: "PNCC", variantId: "065517700" }],
      //       //   ["exterior", "paintwork", "paint"]
      //       // );
      //       const test: Option | undefined = vehicleConfigurationMeta.getOption(
      //         "EXTC",
      //         "364000000"
      //       );
      //       if (test) {
      //         const pathToRoot = test.getPathToRoot();
      //         console.log("config path of opt:", pathToRoot);
      //       }
      //       // console.log('this is color of preconfig:', test)
      //       this.store.dispatch(
      //         setVehicleMeta({ vehicleConfigMeta: vehicleConfigurationMeta })
      //       );
      //       return of(vehicleConfigurationMeta);
      //     })
      //   );
      const configObservable = <Observable<VehicleConfigurationMeta>>(
        combineLatest([
          this.store.select((p) => p.data.car),
          this.fetchGuiStructure(vehicleInfo),
        ]).pipe(
          take(1),
          map((data) => {
            const vehicleInfo = data[0];
            const guiStructure = data[1];
            const vehicleConfigurationMeta =
              this.vehicleParser.buildVehicleConfigurationMeta(
                guiStructure,
                vehicleInfo
              );
            this.logger.logInfo('ModelConfigurationService', LoggingService.LogColors.Blue, 'This is vehicleMeta:', vehicleConfigurationMeta);
            this.store.dispatch(setVehicleMeta({vehicleConfigMeta:vehicleConfigurationMeta}));
            return vehicleConfigurationMeta;
          })
        )
      );

      this.vehicleConfigurationCache.set(vehicleInfo.vehicleId, configObservable);

      // configObservable.subscribe();

      return configObservable;
    } else {
      return cachedConfigObservable;
    }
  }

  private fetchGuiStructure(vehicleInfo: VehicleInfo): Observable<any> {
    const FILE_NAME = `tree-${vehicleInfo.marketId}.json`;
    // const jsonPath = `/rt-assets/data/cars/${vehicleInfo.id}/config/${FILE_NAME}`;

    const cloudPath = `${this.cfg.cloudFrontUrl}${this.cfg.isMuseum ? "/rt-assets/data" : ""}/cars/${vehicleInfo.id}/${this.cfg.isMuseum ? "config/" : ""}${FILE_NAME}`;
    return this.http.get(cloudPath);
  }

  // private removeEmptyConfigLevels(vehicleConfigMeta: VehicleConfigurationMeta) {
  //   vehicleConfigMeta.configuration.configHierarchy =
  //     this.removeEmptyConfigLevelsInternal(
  //       vehicleConfigMeta.configuration.configHierarchy
  //     );
  //   return vehicleConfigMeta;
  // }

  // private removeEmptyConfigLevelsInternal(configLevels: ConfigurationLevel[]) {
  //   return configLevels.filter((configLevel) => {
  //     if (configLevel.children) {
  //       configLevel.children = this.removeEmptyConfigLevelsInternal(
  //         configLevel.children
  //       );
  //     }
  //     const hasOptions = configLevel.hasOptions();
  //     if (!hasOptions) {
  //       console.warn(
  //         `Removing empty config-level [${configLevel
  //           .getPathToRoot()
  //           .join("|")}`,
  //         configLevel
  //       );
  //     }
  //     return hasOptions;
  //   });
  // }
}
